import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PrivateRoute from '../components/PrivateRoute'

export default function LawsByState() {
  const data = useStaticQuery(graphql`
    query LawsByStatePage {
      site {
        siteMetadata {
          pathPrefix
        }
      }
    }
  `)
  const { pathPrefix } = data.site.siteMetadata

  return (
    <PrivateRoute>
      <Layout>
        <SEO title="Laws By State" />
        <div id="content-outline-faq">
          <div id="faq-questions">
            <h1 className="color3">State:</h1>
            <div className="stateDiv1">
              <ul id="general-list">
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Arkansas
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Arizona
                  </a>
                </li>
                <li>
                  {' '}
                  <a href={`${pathPrefix}/popups/Leave_State_Law_CA.html`} target="answers">
                    California
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Colorado
                  </a>
                </li>
                <li>
                  {' '}
                  <a href={`${pathPrefix}/popups/Leave_State_Law_DC.html`} target="answers">
                    District of Columbia
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Florida
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Georgia
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Idaho
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Illinois
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Indiana
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Kentucky
                  </a>
                </li>
                <li>
                  {' '}
                  <a href={`${pathPrefix}/popups/Leave_State_Law_MD.html`} target="answers">
                    Maryland
                  </a>
                </li>
                <li>
                  {' '}
                  <a href={`${pathPrefix}/popups/Leave_State_Law_NJ.html`} target="answers">
                    New Jersey
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    New Mexico
                  </a>
                </li>
              </ul>
            </div>
            <div className="stateDiv2">
              <ul id="general-list">
                <li>
                  <a href={`${pathPrefix}/popups/Leave_State_Law_NY.html`} target="answers">
                    New York
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    North Carolina
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Oklahoma
                  </a>
                </li>
                <li>
                  {' '}
                  <a href={`${pathPrefix}/popups/Leave_State_Law_OH.html`} target="answers">
                    Ohio
                  </a>
                </li>
                <li>
                  {' '}
                  <a href={`${pathPrefix}/popups/Leave_State_Law_OR.html`} target="answers">
                    Oregon
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Pennsylvania
                  </a>
                </li>
                <li>
                  {' '}
                  <a href={`${pathPrefix}/popups/Leave_State_Law_TN.html`} target="answers">
                    Tennessee
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Texas
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Utah
                  </a>
                </li>
                <li>
                  {' '}
                  <a
                    href={`${pathPrefix}/popups/Leave_State_Law_Generic.html`}
                    target="answers"
                  >
                    Virginia
                  </a>
                </li>
                <li>
                  {' '}
                  <a href={`${pathPrefix}/popups/Leave_State_Law_WA.html`} target="answers">
                    Washington
                  </a>
                </li>
                <li>
                  {' '}
                  <a href={`${pathPrefix}/popups/Leave_State_Law_WV.html`} target="answers">
                    West Virginia
                  </a>
                </li>
                <li>
                  {' '}
                  <a href={`${pathPrefix}/popups/Leave_State_Law_WI.html`} target="answers">
                    Wisconsin
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="color2" id="faq-answers">
            <h1 className="color1">State Laws:</h1>
            <iframe
              title="emptyIframe"
              scrolling="no"
              height="350px"
              width="500px"
              frameBorder="0"
              name="answers"
              id="answers"
              allowTransparency="true"
              src={`${pathPrefix}/popups/Leave_State_Law_Start.html#none`}
            />
          </div>
        </div>
      </Layout>
    </PrivateRoute>
  );
}
